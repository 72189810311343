@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
}

.ant-col .ant-form-item-label {
  padding: 0 0 4px;
}

.ant-form-item-label > label {
  height: auto !important;
}

.ant-collapse-item > .ant-collapse-header {
  padding: 16px 0 !important;
}

.secondary-button-custom:hover {
  background-color: #333333 !important;
  color: #ffffff !important;
  border-color: #333333 !important;
}

:where(.css-1tgqkgb).ant-menu-light.ant-menu-root.ant-menu-inline,
:where(.css-1tgqkgb).ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: 0 !important;
}

.ant-collapse-item > .ant-collapse-header {
  padding: 8px 0 !important;
}

:where(.css-1tgqkgb).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

:where(.css-1tgqkgb).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-inline-start: 6px;
  margin-top: 7px;
  color: #ff4d4f;
  font-size: 15px;
  line-height: 1;
  content: '*';
}

:where(.css-1tgqkgb).ant-collapse-large
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0 !important;
}

.cardTitle:hover,
.cardFooterLink:hover {
  text-decoration: underline;
}

mark {
  background-color: #ffcfbe !important;
  border-radius: 5px;
  padding-inline: 5px !important;
}
